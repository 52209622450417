import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { fetchBusiness, fetchMajorBusiness } from "../http/navLinksAPI";
import ShareButton from "../components/UI/ShareButton";

const Business = () => {
    const [business, setBusiness] = useState();
    const [majorBusiness, setMajorBusiness] = useState();
    const [loading, setLoading] = useState(true);

    const printRef = useRef(null);
    const handlePrint = () => {
        const originalBodyContent = document.body.innerHTML;
        document.body.innerHTML = printRef.current.outerHTML;
        window.print();

        onafterprint = (e) => {
            console.log(e);

            document.body.innerHTML = originalBodyContent;
        };
        window.location.reload();
    };

    useEffect(() => {
        fetchBusiness().then((data) => {
            setBusiness(data);
        });
        fetchMajorBusiness()
            .then((data) => {
                setMajorBusiness(data);
            })
            .finally(() => setLoading(false));
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
                <div className="d-flex flex-column col-xl-8 px-xl-4" ref={printRef}>
                    <div className="py-4 block-title text-center">
                        <span className="text-primary">Afaceri</span>
                    </div>
                    <span className="text-wrap">{business.count > 0 ? business.rows[0].article : null}</span>
                    <div className="py-4 block-title text-center font-3">
                        <span className="text-primary">Afaceri de importanta majora</span>
                    </div>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className="bg-primary text-white">#</th>
                                <th className="bg-primary text-white">Denumire</th>
                            </tr>
                        </thead>
                        <tbody>
                            {majorBusiness.count > 0
                                ? majorBusiness.rows.map((item, idx) => (
                                      <tr key={idx}>
                                          <td>{idx + 1}</td>
                                          <td>{item.name}</td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </Table>
                    <div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
                        <i
                            role="button"
                            onClick={() => handlePrint()}
                            className="bi bi-printer text-primary icon-3 px-4"
                            style={{
                                fontWeight: "600 !important",
                                WebkitTextStroke: 1,
                            }}
                        />
                        <ShareButton />
                    </div>
                </div>
                <Calendar />
            </div>
        </div>
    );
};

export default Business;
