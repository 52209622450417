import React from "react";
import Calendar from "../components/Calendar";
import { ld_local_public_administration } from "../utils/consts";

const LDLocalPublicAdministration = () => {
	return (
		<div className='d-flex flex-xl-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-xl-5 py-4 col-xl-9 col-12'>
				<div className='block-title pb-4'>
					<span>Administraţie publică locală</span>
				</div>

				<div className='d-flex flex-column justify-content-start shadow rounded'>
					<iframe
						id='widget_iframe'
						className='rounded'
						style={{
							borderWidth: 0,
							width: "100%",
							height: 1000,
							backgroundColor: "white",
							borderColor: "black",
							borderRadius: 0,
						}}
						src={ld_local_public_administration}
					/>
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default LDLocalPublicAdministration;
