import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Tabs, Tab } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Calendar from "../components/Calendar";
import { useNavigate } from "react-router-dom";
import { CITY_NEWS_ROUTE, PHOTO_GALLERY_ROUTE } from "../utils/consts";
import { Spinner } from "react-bootstrap";
import {
    fetchCityNews,
    fetchCommitteeMeetings,
    fetchMayorOnline,
    fetchOnlineMeetings,
    fetchOther,
    fetchPhotoGallery,
} from "../http/NewsAPI";
import city_news_img from "../assets/city_news.png";

const CityNewsList = observer(() => {
    const { cityNews } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCityNews().then((data) => {
            cityNews.setCityNews(data);
        });
        fetchCommitteeMeetings().then((data) => {
            cityNews.setCommitteeMeetings(data);
        });
        fetchPhotoGallery().then((data) => {
            cityNews.setPhotoGallery(data);
        });
        fetchOnlineMeetings().then((data) => {
            cityNews.setOnlineMeetings(data);
        });
        fetchMayorOnline().then((data) => {
            cityNews.setMayorOnline(data);
        });
        fetchOther()
            .then((data) => {
                cityNews.setOther(data);
            })
            .finally(() => setLoading(false));
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
            <div className="py-4 px-5 border-bottom col-md-9">
                <div className="block-title pb-4">
                    <span>Noutațile orașului</span>
                </div>
                <Tabs defaultActiveKey="news" id="fill-tab-example" className="mb-3 text-primary" fill>
                    <Tab eventKey="news" title="Noutăți" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.cityNews.count > 0
                                ? cityNews.cityNews.rows.map((item, idx) => {
                                      return (
                                          <Card
                                              key={idx}
                                              style={{ width: "22rem" }}
                                              className="my-3"
                                              onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                          >
                                              {/* <Card.Img
													variant='top'
													src='https://picsum.photos/400/250'
												/> */}
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          item.city_news_files.length > 0
                                                              ? process.env.REACT_APP_API_URL +
                                                                item.city_news_files[0].img
                                                              : city_news_img
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                    <Tab eventKey="commission-meetings" title="Ședințe comisii" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.committeeMeetings.count > 0
                                ? cityNews.committeeMeetings.rows.map((item, idx) => {
                                      return (
                                          <Card key={idx} style={{ width: "22rem" }} className="my-3">
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          process.env.REACT_APP_API_URL + item.img
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                    <Tab eventKey="photos" title="Fotografii" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.photoGallery.count > 0
                                ? cityNews.photoGallery.rows.map((item, idx) => {
                                      return (
                                          <Card
                                              key={idx}
                                              style={{ width: "22rem" }}
                                              className="my-3"
                                              onClick={() => navigate(PHOTO_GALLERY_ROUTE + "/" + item.id)}
                                          >
                                              {/* <Card.Img
													variant='top'
													src={item.photo_gallery_files[0].img}
												/> */}
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          item.photo_gallery_files.length > 0
                                                              ? process.env.REACT_APP_API_URL +
                                                                item.photo_gallery_files[0].img
                                                              : city_news_img
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                    <Tab eventKey="online-meetings" title="Ședințe online" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.onlineMeetings.count > 0
                                ? cityNews.onlineMeetings.rows.map((item, idx) => {
                                      return (
                                          <Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
                                              {/* <Card.Img variant="top" src={item.video_link} /> */}
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          item.link.includes("facebook")
                                                              ? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
                                                              : "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                    <Tab eventKey="mayor-online" title="Președinte CRE Online" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.mayorOnline.count > 0
                                ? cityNews.mayorOnline.rows.map((item, idx) => {
                                      return (
                                          <Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
                                              {/* <Card.Img variant="top" src={item.video_link} /> */}
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          item.link.includes("facebook")
                                                              ? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
                                                              : "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                    <Tab eventKey="another" title="Alte" className="text-primary fw-bold">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                            {cityNews.other.count > 0
                                ? cityNews.other.rows.map((item, idx) => {
                                      return (
                                          <Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
                                              {/* <Card.Img variant="top" src={item.video_link} /> */}
                                              <div
                                                  className="h-100 rounded"
                                                  style={{
                                                      minHeight: "20vh",
                                                      background: `url(${
                                                          item.link.includes("facebook")
                                                              ? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
                                                              : "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
                                                      })  center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <Card.Body>
                                                  <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                                      <span
                                                          className="border border-primary rounded text-primary p-2"
                                                          style={{ fontSize: "1rem" }}
                                                      >
                                                          {item.date}
                                                      </span>
                                                      {item.isNew ? (
                                                          <span
                                                              className="border bg-primary rounded text-white p-2"
                                                              style={{ fontSize: "1rem" }}
                                                          >
                                                              NEW
                                                          </span>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </Card.Title>
                                                  <Card.Text
                                                      onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
                                                      className="truncate"
                                                  >
                                                      {item.title}
                                                  </Card.Text>
                                              </Card.Body>
                                          </Card>
                                      );
                                  })
                                : null}
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Calendar />
        </div>
    );
});

export default CityNewsList;
